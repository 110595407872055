<template>
  <div class="container">
    <div v-if="!showNewProtocol">
      <vs-button @click="showNewProtocol = true">
        Nuevo Protocolo
      </vs-button>
    </div>
    <div v-if="!showNewProtocol">
      <h2>Mis Protocolos</h2>
      <vs-table striped success>
        <template #thead>
          <vs-tr>
            <vs-th>
              Protocolo
            </vs-th>
            <vs-th>
              Fecha
            </vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr
            ref="expandInfo"
            :key="i"
            v-for="(tr, i) in protocols"
            :data="tr"
          >
            <vs-td>
              {{ tr.name }}
            </vs-td>
            <vs-td>
              {{ tr.created_at }}
            </vs-td>
            <template #expand>
              <div class="con-content">
                <div>
                  <div>
                    <b>Datos Basales:</b>
                    <div><small>Destinado a pacientes</small></div>

                    <vs-button-group class="multiSelectBtns">
                      <vs-button
                        flat
                        icon
                        @click="
                          (tr.basics.addYears = !tr.basics.addYears),
                            toggleCollapse(i)
                        "
                        :disabled="!isEditMode"
                        :active="tr.basics.addYears"
                      >
                        Edad
                      </vs-button>
                      <vs-button
                        flat
                        icon
                        :disabled="!isEditMode"
                        @click="
                          (tr.basics.addWeight = !tr.basics.addWeight),
                            toggleCollapse(i)
                        "
                        :active="tr.basics.addWeight"
                      >
                        Peso
                      </vs-button>
                      <vs-button
                        flat
                        icon
                        :disabled="!isEditMode"
                        @click="
                          (tr.basics.addHeight = !tr.basics.addHeight),
                            toggleCollapse(i)
                        "
                        :active="tr.basics.addHeight"
                      >
                        Altura
                      </vs-button>
                      <vs-button
                        flat
                        icon
                        :disabled="!isEditMode"
                        @click="
                          (tr.basics.addGender = !tr.basics.addGender),
                            toggleCollapse(i)
                        "
                        :active="tr.basics.addGender"
                      >
                        Género
                      </vs-button>
                      <vs-button
                        flat
                        icon
                        :disabled="!isEditMode"
                        @click="
                          (tr.basics.addAntecedentes = !tr.basics
                            .addAntecedentes),
                            toggleCollapse(i)
                        "
                        :active="tr.basics.addAntecedentes"
                      >
                        Antecedentes Personales
                      </vs-button>
                    </vs-button-group>
                    <div><small>Destinado a doctores</small></div>

                    <vs-button-group
                      class="multiSelectBtns"
                      @change="toggleCollapse(i)"
                    >
                      <vs-button
                        flat
                        icon
                        :disabled="!isEditMode"
                        @click="
                          (tr.basics.addDiagnotic = !tr.basics.addDiagnotic),
                            toggleCollapse(i)
                        "
                        :active="tr.basics.addDiagnotic"
                      >
                        Diagnóstico
                      </vs-button>
                      <vs-button
                        flat
                        icon
                        :disabled="!isEditMode"
                        @click="
                          (tr.basics.addTratamiento = !tr.basics
                            .addTratamiento),
                            toggleCollapse(i)
                        "
                        :active="tr.basics.addTratamiento"
                      >
                        Tratamiento
                      </vs-button>
                    </vs-button-group>
                  </div>
                </div>
                <div>
                  <div>
                    <b>Hojas quirúrgica:</b>
                  </div>

                  <div>
                    <vs-checkbox
                      @change="resetHojas(tr), toggleCollapse(i)"
                      v-model="tr.addHojas"
                      v-if="isEditMode"
                    >
                      Desea Añadir una hoja quirúrgica?
                    </vs-checkbox>
                    <p v-else>{{ tr.addHojas ? "Añadido" : "No añadido" }}</p>
                    <!-- <div class="hojasSelects">
                      <vs-select
                        filter
                        multiple
                        :disabled="!isEditMode"
                        @select="(e) => toggleCollapse(i, e)"
                        :state="tr.hojas.region === '' ? 'warn' : 'success'"
                        placeholder="Selecciona la región"
                        v-model="tr.hojas.region"
                      >
                        <vs-option label="Extremidad Inf." value="1">
                          Extremidad Inf.
                        </vs-option>
                        <vs-option label="Extremidad Sup." value="2">
                          Extremidad Sup.
                        </vs-option>
                        <vs-option label="Columna" value="3">
                          Columna
                        </vs-option>
                      </vs-select>

                      <vs-select
                        v-if="tr.hojas.region"
                        filter
                        multiple
                        :disabled="!isEditMode"
                        @input="toggleCollapse(i)"
                        :state="
                          tr.hojas.articulacion === '' ? 'warn' : 'success'
                        "
                        placeholder="Selecciona la articulación"
                        v-model="tr.hojas.articulacion"
                      >
                        <vs-option label="Cadera" value="1">
                          Cadera
                        </vs-option>
                        <vs-option label="Rodilla" value="2">
                          Rodilla
                        </vs-option>
                        <vs-option label="Pie y tobillo" value="3">
                          Pie y tobillo
                        </vs-option>
                      </vs-select>

                      <vs-select
                        v-if="tr.hojas.articulacion"
                        filter
                        multiple
                        :disabled="!isEditMode"
                        @input="toggleCollapse(i)"
                        :state="tr.hojas.type === '' ? 'warn' : 'success'"
                        placeholder="Selecciona el tipo de cirugía"
                        v-model="tr.hojas.type"
                      >
                        <vs-option label="Primária" value="1">
                          Primária
                        </vs-option>
                        <vs-option label="Revisión" value="2">
                          Revisión
                        </vs-option>
                        <vs-option label="Artroscópia" value="3">
                          Artroscópia
                        </vs-option>
                        <vs-option label="Fractura" value="4">
                          Fractura
                        </vs-option>
                      </vs-select>

                      <vs-select
                        v-if="tr.hojas.type"
                        filter
                        multiple
                        :disabled="!isEditMode"
                        @input="toggleCollapse(i)"
                        :state="tr.hojas.subtype === '' ? 'warn' : 'success'"
                        placeholder="Tipo de Artroscópia"
                        v-model="tr.hojas.subtype"
                      >
                        <vs-option label="Ligamento Cruzado Anterior" value="1">
                          Ligamento Cruzado Anterior
                        </vs-option>
                        <vs-option
                          label="Ligamento Cruzado Posterior"
                          value="2"
                        >
                          Ligamento Cruzado Posterior
                        </vs-option>
                        <vs-option label="Lesión meniscal" value="3">
                          Lesión meniscal
                        </vs-option>
                        <vs-option label="Lesión Condral" value="4">
                          Lesión Condral
                        </vs-option>
                      </vs-select>

                      <vs-select
                        v-if="tr.hojas.subtype"
                        filter
                        multiple
                        :disabled="!isEditMode"
                        @input="toggleCollapse(i)"
                        :state="tr.hojas.lesion === '' ? 'warn' : 'success'"
                        placeholder="Lesión"
                        v-model="tr.hojas.lesion"
                      >
                        <vs-option label="Ruptura" value="1">
                          Ruptura
                        </vs-option>
                        <vs-option label="Revisión" value="2">
                          Revisión
                        </vs-option>
                      </vs-select>

                      <vs-select
                        v-if="tr.hojas.lesion"
                        filter
                        multiple
                        :disabled="!isEditMode"
                        @input="toggleCollapse(i)"
                        :state="
                          tr.hojas.plastiaType === '' ? 'warn' : 'success'
                        "
                        placeholder="Tipo de Artroscópia"
                        v-model="tr.hojas.plastiaType"
                      >
                        <vs-option label="Isquiotibiales" value="1">
                          Isquiotibiales
                        </vs-option>
                        <vs-option label="Tendón rotuliano" value="2">
                          Tendón rotuliano
                        </vs-option>
                        <vs-option label="Aloinjerto" value="3">
                          Aloinjerto
                        </vs-option>
                        <vs-option label="Otro" value="4">
                          Otro
                        </vs-option>
                      </vs-select>

                      <vs-select
                        v-if="tr.hojas.plastiaType"
                        filter
                        multiple
                        :disabled="!isEditMode"
                        @input="toggleCollapse(i)"
                        :state="tr.hojas.fix1 === '' ? 'warn' : 'success'"
                        placeholder="Fijación proximal"
                        v-model="tr.hojas.fix1"
                      >
                        <vs-option label="Tornillo interferencial" value="1">
                          Tornillo interferencial
                        </vs-option>
                        <vs-option label="Sistema de suspensión" value="2">
                          Sistema de suspensión
                        </vs-option>
                      </vs-select>

                      <vs-select
                        v-if="tr.hojas.plastiaType"
                        filter
                        multiple
                        :disabled="!isEditMode"
                        @input="toggleCollapse(i)"
                        :state="tr.hojas.fix2 === '' ? 'warn' : 'success'"
                        placeholder="Fijación distal"
                        v-model="tr.hojas.fix2"
                      >
                        <vs-option label="Tornillo interferencial" value="1">
                          Tornillo interferencial
                        </vs-option>
                        <vs-option label="Sistema de suspensión" value="2">
                          Sistema de suspensión
                        </vs-option>
                      </vs-select>
                    </div> -->

                    <!-- <vs-button-group>
                      <vs-button
                        :disabled="!isEditMode"
                        :active="tr.hojas.addTecnica"
                        flat
                        @click="
                          (tr.hojas.addTecnica = !tr.hojas.addTecnica),
                            toggleCollapse(i)
                        "
                      >
                        Técnica Quirúrgica
                      </vs-button>
                      <vs-button
                        :disabled="!isEditMode"
                        :active="tr.hojas.addIsquemia"
                        flat
                        @click="
                          (tr.hojas.addIsquemia = !tr.hojas.addIsquemia),
                            toggleCollapse(i)
                        "
                      >
                        Tiempo de isquemia
                      </vs-button>
                      <vs-button
                        :disabled="!isEditMode"
                        :active="tr.hojas.addMaterial"
                        flat
                        @click="
                          (tr.hojas.addMaterial = !tr.hojas.addMaterial),
                            toggleCollapse(i)
                        "
                      >
                        Material implantado
                      </vs-button>
                      <vs-button
                        :disabled="!isEditMode"
                        :active="tr.hojas.addComments"
                        flat
                        @click="
                          (tr.hojas.addComments = !tr.hojas.addComments),
                            toggleCollapse(i)
                        "
                      >
                        Comentarios
                      </vs-button>
                    </vs-button-group> -->
                  </div>
                </div>
                <div>
                  <div>
                    <b>Cuestionarios:</b>
                  </div>
                  <vs-button-group class="multiSelectBtns">
                    <vs-button
                      flat
                      @click="
                        (tr.standards.addKnee.status = !tr.standards.addKnee
                          .status),
                          toggleCollapse(i)
                      "
                      :disabled="!isEditMode"
                      :active="tr.standards.addKnee.status"
                    >
                      Knee S.
                    </vs-button>
                    <vs-button
                      flat
                      @click="
                        (tr.standards.addOxford.status = !tr.standards.addOxford
                          .status),
                          toggleCollapse(i)
                      "
                      :disabled="!isEditMode"
                      :active="tr.standards.addOxford.status"
                    >
                      Oxford S.
                    </vs-button>
                    <vs-button
                      flat
                      @click="
                        (tr.standards.addWomac.status = !tr.standards.addWomac
                          .status),
                          toggleCollapse(i)
                      "
                      :disabled="!isEditMode"
                      :active="tr.standards.addWomac.status"
                    >
                      WOMAK
                    </vs-button>
                    <vs-button
                      flat
                      @click="
                        (tr.standards.addAkss.status = !tr.standards.addAkss
                          .status),
                          toggleCollapse(i)
                      "
                      :disabled="!isEditMode"
                      :active="tr.standards.addAkss.status"
                    >
                      AKSS
                    </vs-button>
                    <vs-button
                      flat
                      @click="
                        (tr.standards.addEva.status = !tr.standards.addEva
                          .status),
                          toggleCollapse(i)
                      "
                      :disabled="!isEditMode"
                      :active="tr.standards.addEva.status"
                    >
                      EVA
                    </vs-button>
                  </vs-button-group>
                  <div v-if="tr.standards.addKnee.status">
                    KNEE S :
                    <vs-button-group class="multiSelectBtns">
                      <vs-button
                        flat
                        v-for="(period, ind) in periods"
                        :key="'period_' + ind"
                        :disabled="!isEditMode"
                        :active="
                          tr.standards.addKnee.periods.includes(period.name)
                        "
                        @click="
                          addPeriod(period.name, tr.standards.addKnee),
                            toggleCollapse(i)
                        "
                      >
                        {{ period.name }}
                      </vs-button>
                    </vs-button-group>
                  </div>
                  <div v-if="tr.standards.addOxford.status">
                    OXFORD S :
                    <vs-button-group class="multiSelectBtns">
                      <vs-button
                        flat
                        v-for="(period, ind) in periods"
                        :key="'period_' + ind"
                        :disabled="!isEditMode"
                        :active="
                          tr.standards.addOxford.periods.includes(period.name)
                        "
                        @click="
                          addPeriod(period.name, tr.standards.addOxford),
                            toggleCollapse(i)
                        "
                      >
                        {{ period.name }}
                      </vs-button>
                    </vs-button-group>
                  </div>
                  <div v-if="tr.standards.addWomac.status">
                    WOMAK :
                    <vs-button-group class="multiSelectBtns">
                      <vs-button
                        flat
                        v-for="(period, ind) in periods"
                        :key="'period_' + ind"
                        :disabled="!isEditMode"
                        :active="
                          tr.standards.addWomac.periods.includes(period.name)
                        "
                        @click="
                          addPeriod(period.name, tr.standards.addWomac),
                            toggleCollapse(i)
                        "
                      >
                        {{ period.name }}
                      </vs-button>
                    </vs-button-group>
                  </div>
                  <div v-if="tr.standards.addAkss.status">
                    AKSS :
                    <vs-button-group class="multiSelectBtns">
                      <vs-button
                        flat
                        v-for="(period, ind) in periods"
                        :key="'period_' + ind"
                        :disabled="!isEditMode"
                        :active="
                          tr.standards.addAkss.periods.includes(period.name)
                        "
                        @click="
                          addPeriod(period.name, tr.standards.addAkss),
                            toggleCollapse(i)
                        "
                      >
                        {{ period.name }}
                      </vs-button>
                    </vs-button-group>
                  </div>
                  <div v-if="tr.standards.addEva.status">
                    EVA :
                    <vs-button-group class="multiSelectBtns">
                      <vs-button
                        flat
                        v-for="(period, ind) in periods"
                        :key="'period_' + ind"
                        :disabled="!isEditMode"
                        :active="
                          tr.standards.addEva.periods.includes(period.name)
                        "
                        @click="
                          addPeriod(period.name, tr.standards.addEva),
                            toggleCollapse(i)
                        "
                      >
                        {{ period.name }}
                      </vs-button>
                    </vs-button-group>
                  </div>
                </div>
                <div>
                  <b>Preguntas personalizadas:</b>
                  <div
                    v-for="(question, ind) in tr.questions"
                    :key="'q_' + ind"
                  >
                    Texto:
                    <div>
                      <small v-if="!isEditMode">
                        {{ question.text }}
                      </small>
                      <vs-input
                        v-else
                        v-model="question.text"
                        class="inputForm"
                        label-placeholder="Introduce tu pregunta personalizada"
                      />
                    </div>
                    Destinado:
                    <div>
                      <small v-if="!isEditMode">
                        {{
                          question.destinyType === "1"
                            ? "Pacientes"
                            : question.destinyType === "2"
                            ? "Doctores"
                            : "Todos"
                        }}
                      </small>
                      <vs-select
                        v-else
                        class="inputForm"
                        label-placeholder="Destinado a:"
                        v-model="question.destinyType"
                      >
                        <vs-option label="Pacientes" value="1">
                          Pacientes
                        </vs-option>
                        <vs-option label="Doctores" value="2">
                          Doctores
                        </vs-option>
                        <vs-option label="Todos" value="3">
                          Todos
                        </vs-option>
                      </vs-select>
                    </div>

                    Tipo:
                    <div class="questionsType">
                      <vs-radio
                        val="1"
                        @input="toggleCollapse(i)"
                        :disabled="!isEditMode"
                        v-model="question.type"
                      >
                        Texto libre
                      </vs-radio>
                      <vs-radio
                        val="2"
                        @input="toggleCollapse(i)"
                        :disabled="!isEditMode"
                        v-model="question.type"
                      >
                        Selección
                      </vs-radio>
                      <vs-radio
                        val="3"
                        @input="toggleCollapse(i)"
                        :disabled="!isEditMode"
                        v-model="question.type"
                      >
                        Selección Multiple
                      </vs-radio>
                      <vs-radio
                        val="4"
                        @input="toggleCollapse(i)"
                        :disabled="!isEditMode"
                        v-model="question.type"
                      >
                        Sí / No
                      </vs-radio>
                    </div>
                    {{
                      (isEditMode && question.type.includes("3")) ||
                      question.type.includes("2")
                        ? "Opciones:"
                        : ""
                    }}

                    <div
                      v-for="(option, optInd) in question.options"
                      :key="'optq_' + optInd"
                    >
                      <small v-if="!isEditMode">
                        {{ option.text }}
                      </small>
                      <vs-input
                        v-else
                        v-model="option.text"
                        :placeholder="'Opcion ' + optInd"
                      />
                    </div>

                    <vs-button
                      v-if="
                        (isEditMode && question.type.includes('3')) ||
                          question.type.includes('2')
                      "
                      @click="addCustomOption(question.options, i)"
                      size="mini"
                      icon
                    >
                      +
                    </vs-button>
                  </div>
                </div>
                <div class="dialogActionBtns">
                  <vs-button v-if="!isEditMode" warn @click="onEditProtocol(i)">
                    Editar
                  </vs-button>
                  <vs-button v-else success @click="onUpdateProtocol(tr, i)">
                    Guardar
                  </vs-button>
                  <vs-button
                    v-if="!isEditMode"
                    danger
                    @click="onDeleteProtocol(tr, i)"
                  >
                    Eliminar
                  </vs-button>
                  <vs-button
                    v-else
                    danger
                    border
                    @click="onCancelEditProtocol(i)"
                  >
                    Cancelar
                  </vs-button>
                </div>
              </div>
            </template>
          </vs-tr>
        </template>
        <template #notFound>
          No hay ningún protocolo
        </template>
      </vs-table>
    </div>
    <div v-else>
      <h2>Nuevo protocolo</h2>
      <div>
        <div class="generatorForm">
          <vs-input
            class="inputForm"
            label-placeholder="Nombre del protocolo"
            v-model="name"
          />
          <div>
            <b>Datos Basales:</b>
            <div><small>Destinado a pacientes</small></div>

            <vs-button-group class="multiSelectBtns">
              <vs-button
                flat
                icon
                @click="basics.addYears = !basics.addYears"
                :active="basics.addYears"
              >
                Edad
              </vs-button>
              <vs-button
                flat
                icon
                @click="basics.addWeight = !basics.addWeight"
                :active="basics.addWeight"
              >
                Peso
              </vs-button>
              <vs-button
                flat
                icon
                @click="basics.addHeight = !basics.addHeight"
                :active="basics.addHeight"
              >
                Altura
              </vs-button>
              <vs-button
                flat
                icon
                @click="basics.addGender = !basics.addGender"
                :active="basics.addGender"
              >
                Género
              </vs-button>
              <vs-button
                flat
                icon
                @click="basics.addAntecedentes = !basics.addAntecedentes"
                :active="basics.addAntecedentes"
              >
                Antecedentes personales
              </vs-button>
            </vs-button-group>
            <div><small>Destinado a doctores</small></div>

            <vs-button-group class="multiSelectBtns">
              <vs-button
                flat
                icon
                @click="basics.addDiagnotic = !basics.addDiagnotic"
                :active="basics.addDiagnotic"
              >
                Diagnóstico
              </vs-button>
              <vs-button
                flat
                icon
                @click="basics.addTratamiento = !basics.addTratamiento"
                :active="basics.addTratamiento"
              >
                Tratamiento
              </vs-button>
            </vs-button-group>
          </div>

          <div>
            <b>Hojas quirúrgica:</b>
            <vs-checkbox @change="resetHojas" v-model="addHojas">
              Desea Añadir una hoja quirúrgica?
            </vs-checkbox>

            <!-- <div v-if="addHojas" class="hojasSelects">
              <vs-select
                filter
                multiple
                :state="hojas.region === '' ? 'warn' : 'success'"
                placeholder="Selecciona la región"
                v-model="hojas.region"
              >
                <vs-option label="Extremidad Inf." value="1">
                  Extremidad Inf.
                </vs-option>
                <vs-option label="Extremidad Sup." value="2">
                  Extremidad Sup.
                </vs-option>
                <vs-option label="Columna" value="3">
                  Columna
                </vs-option>
              </vs-select>

              <vs-select
                v-if="hojas.region"
                filter
                multiple
                :state="hojas.articulacion === '' ? 'warn' : 'success'"
                placeholder="Selecciona la articulación"
                v-model="hojas.articulacion"
              >
                <vs-option label="Cadera" value="1">
                  Cadera
                </vs-option>
                <vs-option label="Rodilla" value="2">
                  Rodilla
                </vs-option>
                <vs-option label="Pie y tobillo" value="3">
                  Pie y tobillo
                </vs-option>
              </vs-select>

              <vs-select
                v-if="hojas.articulacion"
                filter
                multiple
                :state="hojas.type === '' ? 'warn' : 'success'"
                placeholder="Selecciona el tipo de cirugía"
                v-model="hojas.type"
              >
                <vs-option label="Primária" value="1">
                  Primária
                </vs-option>
                <vs-option label="Revisión" value="2">
                  Revisión
                </vs-option>
                <vs-option label="Artroscópia" value="3">
                  Artroscópia
                </vs-option>
                <vs-option label="Fractura" value="4">
                  Fractura
                </vs-option>
              </vs-select>

              <vs-select
                v-if="hojas.type"
                filter
                multiple
                :state="hojas.subtype === '' ? 'warn' : 'success'"
                placeholder="Tipo de Artroscópia"
                v-model="hojas.subtype"
              >
                <vs-option label="Ligamento Cruzado Anterior" value="1">
                  Ligamento Cruzado Anterior
                </vs-option>
                <vs-option label="Ligamento Cruzado Posterior" value="2">
                  Ligamento Cruzado Posterior
                </vs-option>
                <vs-option label="Lesión meniscal" value="3">
                  Lesión meniscal
                </vs-option>
                <vs-option label="Lesión Condral" value="4">
                  Lesión Condral
                </vs-option>
              </vs-select>

              <vs-select
                v-if="hojas.subtype"
                filter
                multiple
                :state="hojas.lesion === '' ? 'warn' : 'success'"
                placeholder="Lesión"
                v-model="hojas.lesion"
              >
                <vs-option label="Ruptura" value="1">
                  Ruptura
                </vs-option>
                <vs-option label="Revisión" value="2">
                  Revisión
                </vs-option>
              </vs-select>

              <vs-select
                v-if="hojas.lesion"
                filter
                multiple
                :state="hojas.plastiaType === '' ? 'warn' : 'success'"
                placeholder="Tipo de Artroscópia"
                v-model="hojas.plastiaType"
              >
                <vs-option label="Isquiotibiales" value="1">
                  Isquiotibiales
                </vs-option>
                <vs-option label="Tendón rotuliano" value="2">
                  Tendón rotuliano
                </vs-option>
                <vs-option label="Aloinjerto" value="3">
                  Aloinjerto
                </vs-option>
                <vs-option label="Otro" value="4">
                  Otro
                </vs-option>
              </vs-select>

              <vs-select
                v-if="hojas.plastiaType"
                filter
                multiple
                :state="hojas.fix1 === '' ? 'warn' : 'success'"
                placeholder="Fijación proximal"
                v-model="hojas.fix1"
              >
                <vs-option label="Tornillo interferencial" value="1">
                  Tornillo interferencial
                </vs-option>
                <vs-option label="Sistema de suspensión" value="2">
                  Sistema de suspensión
                </vs-option>
              </vs-select>

              <vs-select
                v-if="hojas.plastiaType"
                filter
                multiple
                :state="hojas.fix2 === '' ? 'warn' : 'success'"
                placeholder="Fijación distal"
                v-model="hojas.fix2"
              >
                <vs-option label="Tornillo interferencial" value="1">
                  Tornillo interferencial
                </vs-option>
                <vs-option label="Sistema de suspensión" value="2">
                  Sistema de suspensión
                </vs-option>
              </vs-select>
            </div> -->

            <!-- <vs-button-group v-if="addHojas">
              <vs-button
                :active="hojas.addTecnica"
                flat
                @click="hojas.addTecnica = !hojas.addTecnica"
              >
                Técnica Quirúrgica
              </vs-button>
              <vs-button
                :active="hojas.addIsquemia"
                flat
                @click="hojas.addIsquemia = !hojas.addIsquemia"
              >
                Tiempo de isquemia
              </vs-button>
              <vs-button
                :active="hojas.addMaterial"
                flat
                @click="hojas.addMaterial = !hojas.addMaterial"
              >
                Material implantado
              </vs-button>
              <vs-button
                :active="hojas.addComments"
                flat
                @click="hojas.addComments = !hojas.addComments"
              >
                Comentarios
              </vs-button>
            </vs-button-group> -->
          </div>

          <div>
            <b>¿Qué cuestionarios desea preguntar?</b>
            <!-- <vs-button-group class="multiSelectBtns">
              <vs-button
                flat
                icon
                @click="standards.addKnee.status = !standards.addKnee.status"
                :active="standards.addKnee.status"
              >
                Knee S.
              </vs-button>
              <vs-button
                flat
                icon
                @click="
                  standards.addOxford.status = !standards.addOxford.status
                "
                :active="standards.addOxford.status"
              >
                Oxford S.
              </vs-button>
              <vs-button
                flat
                icon
                @click="standards.addWomac.status = !standards.addWomac.status"
                :active="standards.addWomac.status"
              >
                WOMAK
              </vs-button>
              <vs-button
                flat
                icon
                @click="standards.addAkss.status = !standards.addAkss.status"
                :active="standards.addAkss.status"
              >
                AKSS
              </vs-button>
              <vs-button
                flat
                icon
                @click="standards.addEva.status = !standards.addEva.status"
                :active="standards.addEva.status"
              >
                EVA
              </vs-button>
            </vs-button-group> -->
            <vs-button 
            floating info flat 
                v-if="showBack"                           
                            @click="onBackClick" 
                        >
                        Atrás
                      </vs-button>
            <div class="cardGrid">
                <div class="cardTile" 
                      v-for="tile, tileInd in selectedFolders" :key="tileInd+'_tile'"
                      @click="filterFolders(tile)"
                >
                  
                      {{tile.name}}
                </div>
            </div>
            <vs-button-group class="multiSelectBtns">
            
              <vs-button
                v-for="(form, ind) in filteredNoStadards"
                :key="'noStandart_' + ind"
                flat
                icon
                @click="filteredNoStadards[ind].status = !filteredNoStadards[ind].status"
                :active="filteredNoStadards[ind].status"
              >
                {{ form.name }}
              </vs-button>
            </vs-button-group>

            <div
              v-for="(form, ind2) in noStandards"
              :key="'noStandart2_' + ind2"
            >
              <div v-if="noStandards[ind2].status">
                {{ form.name }}
                <vs-button-group class="multiSelectBtns">
                  <vs-button
                    flat
                    v-for="(period, ind) in periods"
                    :key="'period_' + ind"
                    :active="noStandards[ind2].periods.includes(period.name)"
                    @click="addPeriod(period.name, noStandards[ind2])"
                  >
                    {{ period.name }}
                  </vs-button>
                </vs-button-group>
              </div>
            </div>

            <div v-if="standards.addKnee.status">
              KNEE S :
              <vs-button-group class="multiSelectBtns">
                <vs-button
                  flat
                  v-for="(period, ind) in periods"
                  :key="'period_' + ind"
                  :active="standards.addKnee.periods.includes(period.name)"
                  @click="addPeriod(period.name, standards.addKnee)"
                >
                  {{ period.name }}
                </vs-button>
              </vs-button-group>
            </div>
            <div v-if="standards.addOxford.status">
              OXFORD S :
              <vs-button-group class="multiSelectBtns">
                <vs-button
                  flat
                  v-for="(period, ind) in periods"
                  :key="'period_' + ind"
                  :active="standards.addOxford.periods.includes(period.name)"
                  @click="addPeriod(period.name, standards.addOxford)"
                >
                  {{ period.name }}
                </vs-button>
              </vs-button-group>
            </div>
            <div v-if="standards.addWomac.status">
              WOMAK :
              <vs-button-group class="multiSelectBtns">
                <vs-button
                  flat
                  v-for="(period, ind) in periods"
                  :key="'period_' + ind"
                  :active="standards.addWomac.periods.includes(period.name)"
                  @click="addPeriod(period.name, standards.addWomac)"
                >
                  {{ period.name }}
                </vs-button>
              </vs-button-group>
            </div>
            <div v-if="standards.addAkss.status">
              AKSS :
              <vs-button-group class="multiSelectBtns">
                <vs-button
                  flat
                  v-for="(period, ind) in periods"
                  :key="'period_' + ind"
                  :active="standards.addAkss.periods.includes(period.name)"
                  @click="addPeriod(period.name, standards.addAkss)"
                >
                  {{ period.name }}
                </vs-button>
              </vs-button-group>
            </div>
            <div v-if="standards.addEva.status">
              EVA :
              <vs-button-group class="multiSelectBtns">
                <vs-button
                  flat
                  v-for="(period, ind) in periods"
                  :key="'period_' + ind"
                  :active="standards.addEva.periods.includes(period.name)"
                  @click="addPeriod(period.name, standards.addEva)"
                >
                  {{ period.name }}
                </vs-button>
              </vs-button-group>
            </div>
          </div>

          <div>
            <b>Preguntas personalizadas:</b>

            <div v-for="(question, ind) in questions" :key="'question_' + ind">
              Texto de la pregunta:
              <vs-input
                v-model="question.text"
                class="inputForm"
                label-placeholder="Introduce tu pregunta personalizada"
              />

              Tipo:
              <div class="questionsType">
                <vs-radio val="1" v-model.lazy="question.type">
                  Texto libre
                </vs-radio>
                <vs-radio val="2" v-model="question.type">
                  Selección
                </vs-radio>
                <vs-radio val="3" v-model="question.type">
                  Selección Multiple
                </vs-radio>
                <vs-radio val="4" v-model="question.type">
                  Sí / No
                </vs-radio>
              </div>
              <div
                v-if="
                  question.type.includes('3') || question.type.includes('2')
                "
              >
                Opciones:
                <div
                  v-for="(option, optInd) in question.options"
                  :key="'opt_' + optInd"
                >
                  <vs-input
                    v-model="option.text"
                    :placeholder="'Opcion ' + optInd"
                  />
                </div>
                <vs-button
                  @click="addCustomOption(question.options)"
                  size="mini"
                  icon
                >
                  +
                </vs-button>
              </div>

              Destinado a:
              <vs-select
                class="inputForm"
                label-placeholder="Destinado a:"
                v-model="question.destinyType"
              >
                <vs-option label="Pacientes" value="1">
                  Pacientes
                </vs-option>
                <vs-option label="Doctores" value="2">
                  Doctores
                </vs-option>
                <vs-option label="Todos" value="3">
                  Todos
                </vs-option>
              </vs-select>
              Preguntar en:
              <vs-select
                class="inputForm"
                filter
                multiple
                label-placeholder="Escoger Temporalidad:"
                v-model="question.temporality"
              >
                <vs-option-group>
                  <div slot="title">
                    Pre Operatorio
                  </div>
                  <vs-option label="Pre Operatorio" value="-1">
                    Pre Operatorio
                  </vs-option>
                </vs-option-group>
                <vs-option-group>
                  <div slot="title">
                    Post Operatorio
                  </div>
                  <vs-option label="15 Días" value="15">
                    15 Días
                  </vs-option>
                  <vs-option label="1 Mes" value="30">
                    1 Mes
                  </vs-option>
                  <vs-option label="2 Meses" value="60">
                    2 Meses
                  </vs-option>
                  <vs-option label="3 Meses" value="90">
                    3 Meses
                  </vs-option>
                  <vs-option label="6 Meses" value="180">
                    6 Meses
                  </vs-option>
                  <vs-option label="1 año" value="360">
                    1 año
                  </vs-option>
                </vs-option-group>
              </vs-select>
              <div class="removeBtn">
                <vs-button @click="removeQuestion(ind)" danger size="mini">
                  Eliminar
                </vs-button>
              </div>
            </div>

            <vs-button @click="addCustomQuestion" size="mini">
              Añadir pregunta
            </vs-button>
          </div>
        </div>
        <div class="footerBtnCnt">
          <vs-button @click="onGenerate" success block>
            Generar
          </vs-button>
          <vs-button
            @click="showNewProtocol = !showNewProtocol"
            transparent
            danger
            block
          >
            Cancelar
          </vs-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { protocolMix, folderMix } from "@/mixins/mixins.js";

export default {
  name: "ProtocolsPage",
  mixins: [protocolMix, folderMix],
  data() {
    return {
      name: "",
      showBack: false,
      allFolders: [],
      selectedFolders: [],
      selectedLV: [],
      basics: {
        addYears: false,
        addWeight: false,
        addHeight: false,
        addGender: false,
        addAntecedentes: false,
        addDiagnotic: false,
        addTratamiento: false,
      },
      standards: {
        addKnee: { status: false, periods: [] },
        addOxford: { status: false, periods: [] },
        addWomac: { status: false, periods: [] },
        addAkss: { status: false, periods: [] },
        addEva: { status: false, periods: [] },
      },
      noStandards: [],
      filteredNoStadards:[],
      questions: [],
      hojas: {
        addIsquemia: false,
        addTecnica: false,
        addMaterial: false,
        addComments: false,
        region: "",
        articulacion: "",
        type: "",
        subtype: "",
        lesion: "",
        plastiaType: "",
        fix1: "",
        fix2: "",
      },
      addHojas: false,
      showNewProtocol: false,
      isEditMode: false,
      protocols: [],
      periods: [
        {
          name: "Pre Operatorio",
          value: -90,
          status: false,
        },
        {
          name: "15 Días",
          value: 15,
          status: false,
        },
        {
          name: "1 Mes",
          value: 30,
          status: false,
        },
        {
          name: "2 Meses",
          value: 60,
          status: false,
        },
        {
          name: "3 Meses",
          value: 90,
          status: false,
        },
        {
          name: "6 Meses",
          value: 180,
          status: false,
        },
      ],
      datacollection: {},
      loaded: false,
      options: {
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
  methods: {
    toggleCollapse(ind) {
      const { expandInfo } = this.$refs;
      expandInfo[ind].$el.click();
      expandInfo[ind].$el.click();
    },

    resetHojas(tr) {
      if (!this.addHojas && !tr) {
        this.hojas = {
          addIsquemia: false,
          addTecnica: false,
          addMaterial: false,
          addComments: false,
        };
      } else if (!this.addHojas && tr) {
        tr.hojas = {
          addIsquemia: false,
          addTecnica: false,
          addMaterial: false,
          addComments: false,
        };
      }
    },

    addPeriod(period, form) {
      if (form.periods.includes(period)) {
        form.periods = form.periods.filter((per) => per !== period);
      } else {
        form.periods.push(period);
      }
    },

    async loadProtocols() {
      const { currentUserInfo } = this.$store.state;
      const uid = currentUserInfo.uid
        ? currentUserInfo.uid
        : currentUserInfo.user.uid;
      // this.folders = await this.getFolders2();
      this.protocols = await this.getProtocols(uid);
      const aNoStandards = await this.getStandardProtocols();
      aNoStandards.forEach((noStand) => {
        noStand.status = false;
        noStand.periods = [];
      });
      this.noStandards = [...aNoStandards];
      this.filteredNoStadards = [...aNoStandards].filter(prot => prot.form.folder === this.selectedLV);
    },

    async loadFolders() {
      const folders =   await this.getFolders();
      this.selectedLV = '0';
      this.allFolders = folders;
      this.selectedFolders = filterByLV(folders, this.selectedLV);
      this.showBack=false;
    },

    filterFolders(tile){
      this.selectedLV = tile.id; 
      this.showBack = true;
      this.selectedFolders = filterByLV(this.allFolders, tile.id)
      this.filteredNoStadards = this.noStandards.filter(prot => prot.form.folder === this.selectedLV);
    },

    onBackClick(){
      const getParent = (folders, son) => folders.filter((folder) => folder.id === son);
      const parent = getParent(this.allFolders, this.selectedLV)[0];
                        
                                if(parent){
                                    if (parent.parent === "0") {
                                        this.showBack = false;
                                    }
                                    this.selectedLV=parent.parent;
                                    this.selectedFolders = filterByLV(this.allFolders, parent.parent)
                                }else{
                                    this.showBack=false;
                                }
    },

    async onDeleteProtocol(protocol, rowInd) {
      const { id } = protocol;
      await this.deleteProtocol(id);
      const ind = this.protocols.findIndex((protocol) => {
        return protocol.id === id;
      });
      if (ind > -1) {
        const { expandInfo } = this.$refs;
        expandInfo[rowInd].$el.click();
        this.protocols.splice(ind, 1);
      }
    },

    onEditProtocol(ind) {
      this.isEditMode = true;
      this.toggleCollapse(ind);
    },

    onCancelEditProtocol(ind) {
      this.isEditMode = false;
      this.toggleCollapse(ind);
    },

    async onUpdateProtocol(data, ind) {
      await this.updateProtocolData(data.id, data);
      this.isEditMode = false;
      this.toggleCollapse(ind);
    },

    async onGenerate() {
      const { currentUserInfo } = this.$store.state;
      const uid = currentUserInfo.uid
        ? currentUserInfo.uid
        : currentUserInfo.user.uid;
      const {
        name,
        basics,
        standards,
        noStandards,
        questions,
        hojas,
        addHojas,
      } = this;
      const data = {
        name,
        basics,
        standards,
        noStandards,
        questions,
        hojas,
        addHojas,
        created_at: new Date().toLocaleDateString(),
        created_by: uid,
      };
      const newProtocol = await this.generateProtocol(data);
      data.id = newProtocol.id;
      this.protocols.push(data);
      this.name = "";
      this.basics = {
        addYears: false,
        addWeight: false,
        addHeight: false,
        addGender: false,
        addAntecedentes: false,
        addTratamiento: false,
        addDiagnotic: false,
      };
      this.standards = {
        addKnee: { status: false, periods: [] },
        addOxford: { status: false, periods: [] },
        addWomac: { status: false, periods: [] },
        addAkss: { status: false, periods: [] },
        addEva: { status: false, periods: [] },
      };
      this.questions = [];
      this.hojas = [];
      this.showNewProtocol = false;
    },

    addCustomQuestion() {
      const data = {
        text: "",
        type: [],
        destinyType: [],
        temporality: [],
        options: [],
      };
      this.questions.push(data);
    },

    removeQuestion(ind) {
      this.questions.splice(ind, 1);
    },

    addCustomOption(options, ind = null) {
      const data = {
        text: "",
      };
      if (ind) {
        options.push(data);
        this.toggleCollapse(ind);
      } else {
        options.push(data);
      }
    },
  },

  mounted() {
    this.loadProtocols();
    this.loadFolders();
  },
};

const filterByLV = (folders, parent) => folders.filter((folder)=> folder.parent === parent);

</script>

<style scoped>

.cardTile{
  padding: 1rem 2rem;
    border-top: 3px solid #17a2b8;
    box-shadow: 3px 3px 16px #02162a63;
    text-align: center;
    border-radius: 0.5rem;
    font-weight: bold;
    margin: 1rem;
    cursor: pointer;
}

.cardGrid{
  display: grid;
  grid-template:
    ". . . ."
    ". . . .";
  grid-row-gap: 0rem;
}

.hojasSelects {
  display: grid;
  grid-template:
    ". . ."
    ". . .";
  grid-row-gap: 2rem;
  margin-bottom: 2rem;
}

.container {
  text-align: justify;
  padding: 4rem 1rem 4rem 4.5rem;
}

.questionsType {
  display: flex;
  justify-content: space-evenly;
}

.removeBtn {
  display: flex;
  justify-content: flex-end;
}

.dialogActionBtns {
  display: flex;
  justify-content: space-between;
}

.userInfo {
  text-align: start;
}

.footerBtnCnt {
  padding: 2rem;
}

.multiSelectBtns {
  margin: 1rem 0rem;
}

.inputForm {
  margin: 2rem 1rem;
}
</style>
<style>
.inputForm > div > input {
  width: 100%;
}
.inputForm > div > .vs-select__input .simple {
  max-width: none;
  width: 100%;
}
</style>
